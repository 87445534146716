import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

class Index extends React.PureComponent {
    render() {

        // const contentfulTitle = this.props.data.contentfulHomepage.title;
        // const contentfulDescription = this.props.data.contentfulHomepage.seoDescription;
        // const contentfulTitle = this.props.data.contentfulHomepage.pageTitle;

        // const { siteName, siteUrl, siteTagline } = this.props.data.site.siteMetadata;
        // const pageTitle = siteName + ` | ` + siteTagline;

        // const siteUrlWithDefaultLocale = siteUrl + '/' + this.props.data.site.siteMetadata.languages.defaultLangKey;
        // const pageDescription = contentfulDescription || siteDescription;

        return (<Helmet>
            <html lang="en" />
            <title>Welkom bij Kraaijeveld | Tailor-made Fresh Produce</title>
            <meta charset="UTF-8" />
            {/*<link rel="canonical" href={siteUrlWithDefaultLocale} />*/}
            <meta property="og:locale" content="nl-NL" />
            {/*<meta property="og:type" content="website" />*/}
            {/*<meta property="og:title" content={pageTitle} />*/}
            {/*<meta property="og:description" content={pageDescription} />*/}
            {/*<meta property="og:url" content={siteUrlWithDefaultLocale} />*/}
            {/*<meta property="og:site_name" content={siteName} />*/}
            {/*<meta property="og:image" content={siteImage} />*/}
            {/*<meta property="og:image:width" content={siteImageWidth} />*/}
            {/*<meta property="og:image:height" content={siteImageHeight} />*/}
            {/*<meta name="twitter:card" content="summary" />*/}
            {/*<meta name="twitter:description" content={pageDescription} />*/}
            {/*<meta name="twitter:title" content={pageTitle} />*/}
            {/*<meta name="twitter:site" content={twitter} />*/}
            {/*<meta name="twitter:image" content={siteImage} />*/}
            {/*<meta name="twitter:creator" content={twitter} />*/}
            {/*<link rel="apple-touch-icon" sizes="180x180" href="https://www.incentro.com/favicon/apple-touch-icon.png" />*/}
            {/*<link rel="icon" type="image/png" href="https://www.incentro.com/favicon/favicon-32x32.png" sizes="32x32" />*/}
            {/*<link rel="icon" type="image/png" href="https://www.incentro.com/favicon/favicon-16x16.png" sizes="16x16" />*/}
            {/*<link rel="manifest" href="https://www.incentro.com/favicon/manifest.json" />*/}
            {/*<link rel="mask-icon" href="https://www.incentro.com/favicon/safari-pinned-tab.svg" color="#f58233" />*/}
            {/*<link rel="shortcut icon" href="https://www.incentro.com/favicon/favicon.ico" />*/}
            {/*<meta name="msapplication-config" content="https://www.incentro.com/favicon/browserconfig.xml" />*/}
            <meta name="theme-color" content="#ffffff" />
            {/*<link rel="preload" as="font" type="font/woff2" crossorigin="anonymous" href="/assets/fonts/dinpro_bold.woff" />*/}
            {/*<link rel="preload" as="font" type="font/woff2" crossorigin="anonymous" href="/assets/fonts/dinpro_regular.woff" />*/}
        </Helmet>);
    }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteName
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
